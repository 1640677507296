<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="box" style="margin-top:10px; border: none!important;">
                        <!--Main Start-->
                        <div class="container">
                            <div class="col-md-12">
                                <h3>Congratulations !!!</h3>
                                <p class="alert alert-success">Your payment was successful.<br />
                                    Now you have to re-login to start using your premium features.<br />
                                    You will be automatically logged out in few seconds.
                                </p>
                                <router-link to="/login" class="btn btn-default" @click="logout">
                                    Logout
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
</template>

<script>
import { useUser } from '@/app/use/user';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Success',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
    setup() {
        const { logout } = useUser();

        setTimeout(function () {
            logout();
        }, 5000);

        return { logout };
    },
};
</script>
